<template>
  <main>
    <section>
      <div class="page-success">
        <div class="verify text-center" style="width: 150px; margin: 10px auto">
          <LoginLogoIcon /><br /><br />
          <LoginLogoName />
        </div>
        <h1>
          <!-- <i class="fa fa-exclamation-circle"></i> Email verification sent. -->
          <i class="fa fa-exclamation-circle"></i> Thank you for registering.
        </h1>
        <!-- <h3 class="text-center">
          Please check your inbox and click the link<br />to verify your email
          address.
        </h3> -->
        <h3 class="text-center">
          An account manager will be in touch<br />with your login details soon.
        </h3>
      </div>
    </section>
  </main>
</template>
<script>
import LoginLogoIcon from "@/components/LoginLogoIcon.vue";
import LoginLogoName from "@/components/LoginLogoName.vue";
export default {
  name: "Verify",
  components: {
    LoginLogoIcon,
    LoginLogoName,
  },
  data() {
    return {
      status: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
